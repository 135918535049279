/**
 * @module CustomerSupport
 * @summary This module exports the CustomerSupport component
 */
import React, {Component} from 'react'
import {
    Grid,
    withStyles,
    TextField,
    CircularProgress
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import withSharedSnackbar from '../../hoc/withSharedSnackbar'
import gql from 'graphql-tag'
import {withApollo} from 'react-apollo'
import ValueCard from '../ValueCard'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PersonIcon from '@material-ui/icons/Person'
import HomeIcon from '@material-ui/icons/Home'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'
import PhoneIcon from '@material-ui/icons/Phone'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Tooltip from '@material-ui/core/Tooltip'


const style = theme => ({
    searchClass: {
        marginBottom: '10px',
        overflow: 'visible'
    },
    textLeft: {
        textAlign: 'left'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    dialogPaper: {
        minHeight: '100vh',
        maxHeight: '100vh',
        minWidth: '100vh',
        maxWidth: '100vh'
    }
})

const GET_EMERGENCY_SUPPORT = gql`
  query getEmergencySupport($uniqueId: String!) {
    getEmergencySupport(uniqueId: $uniqueId) {
      lat
      long
      emergencyContactNumber
      supportStatus
      Remarks
      timestamp
      supportId
    }
  }
`
const GET_DTC_CODES = gql`
  query getDtcCodes(
    $clientLoginId: Int!
    $uniqueId: String
    $timestamp: String
  ) {
    getDtcCodes(
      clientLoginId: $clientLoginId
      uniqueId: $uniqueId
      timestamp: $timestamp
    ) {
      uniqueId
      dtcData {
        ts
        lat
        lng
        dtcCode
        dtcDescription
      }
    }
  }
`

const GET_ALL_ALERT_SUPPORT_MASTER = gql`
  query {
    getAllAlertSupportMaster {
      id
      supportStatus
      createdAt
      updatedAt
      status
    }
  }
`

const GET_USER_EMERGENCY_DETAILS = gql`
  query userDetailsEmergency($vinNumber: String!){
    userDetailsEmergency(vinNumber:$vinNumber){
      fName
      lName
      primaryCallingCode
      primaryNumber
      emergencyCallingCode
      emergencyNumber
      address{
        address1
        address2
        city
        region
        regionCode
        country
        countryCode
        zip
      }
    }
  }
`

const ADD_SUPPORT_STATUS = gql`
  mutation addSupportStatus(
    $supportStatusId: Int!
    $deviceUniqueId: String!
    $partnerLoginId: Int!
    $remarks: String
    $status: Int
    $emergencyTimestamp: String
  ) {
    addSupportStatus(
      supportStatusId: $supportStatusId
      deviceUniqueId: $deviceUniqueId
      partnerLoginId: $partnerLoginId
      remarks: $remarks
      status: $status
      emergencyTimestamp: $emergencyTimestamp
    )
  }
`

const GET_OBD_DATA_POINTS = gql`
  query getObdDataPoints($uniqueId: String!, $timestamp: String) {
    getObdDataPoints(uniqueId: $uniqueId, timestamp: $timestamp) {
      ts
      lat
      lng
      fuel_pump_status
      injection_mode
      ignition_mode
      mil_state
      rpm
      engine_state
      idle_speed
      timer_inclination
      cp_mode
      isc_activation
      input_battery_voltage
      tilt_switch
      engine_stop_request
      VB_Snapshot
      intakeAirTemp
      maf_throttle
      maf
      map_throttle
      imap
      V_TPS_BAS
      TPS_DEG
      raw_angle
      oxy_sensor_voltage
      lambda_setpoint
      inj_timing
      coolant
      vehicleSpeed
      VB_MMV
      mffi_homogeneous_mode
      atmospheric_pressure
      V_TPS_AD_BOL
      ISAPWM_AD_MMV
      TI_HOM_INJ
      IGA_CYL
      MFF_ADAP_ADD
      MFF_ADAP_FAC
      idle_charge
      IGA_ISC
      TI_MES_INJ
      clutch_state
      duty_cycle
      ERT
      tilt_switch_number
      TILT_AD_DGNC
      obdDistance
      bank_angle
      tankLevel
      external_bat_voltage
      pluscode
      ecuinputsignals1
      abseventactive
      abswarninglamp
      front_speed
      frontwheelspeedvalidity
      rear_speed
      rearwheelspeedvalidity
    }
  }
`

/**
 * @summary An array to to display template for pid data
 */
const pidDataTemplate = [
    {name: 'fuel_pump_status', description: 'Electrical Fuel Pump', unit: ''},
    {name: 'injection_mode', description: 'Injection Mode', unit: ''},
    {name: 'ignition_mode', description: 'Ignition Mode', unit: ''},
    {name: 'mil_state', description: 'MIL State', unit: ''},
    {name: 'rpm', description: 'Engine Speed', unit: 'rpm'},
    {name: 'engine_state', description: 'Engine Operating State', unit: ''},
    {name: 'idle_speed', description: 'Target Idle speed', unit: 'rpm'},
    {
        name: 'timer_inclination',
        description: 'Timer Of Vehicle Inclinaison Exceeds The Threshold',
        unit: 's'
    },
    {name: 'cp_mode', description: 'CP Mode', unit: ''},
    {name: 'isc_activation', description: 'ISC Activation', unit: ''},
    {
        name: 'input_battery_voltage',
        description: 'Battery Voltage Input',
        unit: 'V'
    },
    {name: 'tilt_switch', description: 'Tilt Switch Detection', unit: ''},
    {
        name: 'engine_stop_request',
        description: 'Engine Stop Request Cause Of Tilt Detection',
        unit: ''
    },
    {name: 'vb_snapshot', description: 'VB_Snapshot', unit: 'V'},
    {name: 'intakeairtemp', description: 'Intake Air Temperature', unit: '°C'},
    {
        name: 'maf_throttle',
        description: 'Air-Mass Flow At The Throttle',
        unit: 'mg/tdc'
    },
    {name: 'maf', description: 'Air-Mass Flow', unit: 'mg/tdc'},
    {
        name: 'map_throttle',
        description: 'Manifold Air Pressure Upstream Throttle Value',
        unit: 'mb'
    },
    {name: 'imap', description: 'MAP Value By Cylinder', unit: 'mb'},
    {
        name: 'v_tps_bas',
        description: 'V_TPS_BAS,Signal voltage of throttle flap positoion sensor 1',
        unit: 'V'
    },
    {
        name: 'tps_deg',
        description: 'TPS_DEG,Throttle flap opening angle with high resolution',
        unit: '°TPS'
    },
    {
        name: 'raw_angle',
        description: 'Raw Angle From The Potentiometer',
        unit: '°TPS'
    },
    {
        name: 'oxy_sensor_voltage',
        description: 'Upstream Oxygen Sensor Voltage',
        unit: 'V'
    },
    {name: 'lambda_setpoint', description: 'Lambda Setpoint', unit: ''},
    {name: 'inj_timing', description: 'Corrected Inj. Timing', unit: '%'},
    {name: 'coolant', description: 'Coolant Temperature', unit: '°C'},
    {name: 'vehiclespeed', description: 'Vehicle Speed', unit: 'km/h'},
    {
        name: 'vb_mmv',
        description: 'Moving Mean Value Of Battery Voltage',
        unit: 'V'
    },
    {
        name: 'mffi_homogeneous_mode',
        description: 'Mass fuel flow injected for Homogeneous Mode',
        unit: 'mg/tdc'
    },
    {
        name: 'atmospheric_pressure',
        description: 'Atmospheric Pressure',
        unit: 'mb'
    },
    {
        name: 'v_tps_ad_bol',
        description:
            'V_TPS_AD_BOL,Saved voltage value for minimum position of the potentiometer 1',
        unit: 'V'
    },
    {
        name: 'isapwm_ad_mmv',
        description:
            'ISAPWM_AD_MMV,Adaptive value of integral idle speed regulation term',
        unit: '%'
    },
    {
        name: 'ti_hom_inj',
        description: 'TI_HOM_INJ,Fuel injection time for homogeneous mode',
        unit: 'ms'
    },
    {
        name: 'iga_cyl',
        description: 'IGA_CYL, Global Ignition angle applied at cylinder 0',
        unit: '°CRK'
    },
    {
        name: 'mff_adap_add',
        description: 'MFF_ADAP_ADD, Relative additive adaptation factor',
        unit: 'mg/tdc'
    },
    {
        name: 'mff_adap_fac',
        description: 'MFF_ADAP_FAC, Relative multiplicative adaptation factor',
        unit: '%'
    },
    {
        name: 'idle_charge',
        description: 'Calculated Idle Charge Actuator Opening',
        unit: '%'
    },
    {
        name: 'iga_isc',
        description: 'IGA_ISC,PI feedback control for ignition angle',
        unit: '°CRK'
    },
    {
        name: 'ti_mes_inj',
        description: 'TI_MES_INJ, Mesured fuel time delivery during cylinder cycle',
        unit: 'ms'
    },
    {name: 'clutch_state', description: 'Clutch State', unit: ''},
    {
        name: 'duty_cycle',
        description: 'Corrected Duty Cycle Of PWM Signal For CPS Opening',
        unit: '%'
    },
    {name: 'ert', description: 'ERT', unit: ''},
    {
        name: 'tilt_switch_number',
        description: 'Number Of Tilt Switch Detection',
        unit: ''
    },
    {
        name: 'tilt_ad_dgnc',
        description: 'TILT_AD_DGNC, Adaptive value of vehicle inclinaison by DGNC',
        unit: '°'
    },
    {name: 'obddistance', description: 'Odometer', unit: 'km'},
    {name: 'external_bat_voltage', description: 'Battery Voltage', unit: 'V'},
    {name: 'tanklevel', description: 'Fuel Level', unit: '%'},
    {
        name: 'bank_angle',
        description: 'Bank angle',
        unit: '°'
    },
    {
        name: 'ecuinputsignals1',
        description: 'ABS ECU Input Signals',
        unit: ''
    },
    {
        name: 'abseventactive',
        description: 'ABS Event Active',
        unit: ''
    },
    {
        name: 'abswarninglamp',
        description: 'ABS Warning Lamp',
        unit: ''
    },
    {
        name: 'front_speed',
        description: 'ABS Front Speed',
        unit: 'km/h'
    },
    {
        name: 'frontwheelspeedvalidity',
        description: 'ABS Front Wheel Speed Validity',
        unit: ''
    },
    {
        name: 'rear_speed',
        description: 'ABS Rear Speed',
        unit: 'km/h'
    },
    {
        name: 'rearwheelspeedvalidity',
        description: 'ABS Rear Wheel Speed Validity',
        unit: ''
    }
]

/**
 * @summary EmergencySupport component renders form with emergency support menu
 */
class CustomerSupport extends Component {
    constructor() {
        super()
        this.state = {
            supportStatus: '',
            remarks: '',
            phone: '',
            supportAlerts: null,
            emergencyData: null,
            obdData: null,
            dtcData: null,
            pidData: pidDataTemplate,
            enrichedObdData: [],
            enrichedAbsData: [],
            noObdDataPoints: false,
            emergencyDetails: null
        }
    }

    // componentWillMount = async () => {
    //   this.getAllAlertSupportMaster()
    // }
    componentWillMount = async () => {
        this.getAllAlertSupportMaster()
        this.getEmergencySupport()
        this.getDtcCodes()
        this.getObdDataPoints()
        this.getUserDetails(this.props.vehicleNumber)
    }

    /**
     * @function
     * @summary to get alerts list
     */
    getAllAlertSupportMaster = async () => {
        let response = await this.props.client.query({
            query: GET_ALL_ALERT_SUPPORT_MASTER
        })

        if (response && response.data) {
            this.setState({supportAlerts: response.data}, () => {
            })
        }
    }

    /**
     * @function
     * @summary Handles emergency contact data
     */


    getUserDetails = async (vinNum) => {
        let response = await this.props.client.query({
            query: GET_USER_EMERGENCY_DETAILS,
            variables: {
                vinNumber: vinNum
            }
        })
        if (response && response.data) {
            this.setState({emergencyDetails: response.data.userDetailsEmergency})
        }
    }

    /**
     * @function
     * @summary Handles emergency support data
     */
    getEmergencySupport = async () => {
        let response = await this.props.client.query({
            query: GET_EMERGENCY_SUPPORT,
            variables: {
                uniqueId: this.props.uniqueId
            }
        })

        if (response && response.data) {
            this.setState({
                emergencyData: response.data,
                remarks: response.data.getEmergencySupport.Remarks,
                supportStatus: response.data.getEmergencySupport.supportId
            })
        }
        console.log(this.state.emergencyData)
    }

    /**
     * @function
     * @summary Handles support status
     */
    addSupportStatus = async () => {
        if (!this.state.supportStatus)
        {
            this.props.openSnackbar('Please select the support status', {
                type: 'warning'
            })
        } else {
            let response = await this.props.client.mutate({
                mutation: ADD_SUPPORT_STATUS,
                variables: {
                    supportStatusId: parseInt(this.state.supportStatus, 10),
                    deviceUniqueId: this.props.uniqueId,
                    partnerLoginId: parseInt(localStorage.getItem('partnerLoginId'), 10),
                    remarks: this.state.remarks,
                    status: 1,
                    emergencyTimestamp: this.state.emergencyData.getEmergencySupport
                        .timestamp
                },
                errorPolicy: 'all'
            })

            if (response && response.data) {
                this.setState(
                    {
                        supportStatus: '',
                        remarks: '',
                        phone: '',
                        supportAlerts: null,
                        emergencyData: null,
                        obdData: null,
                        dtcData: null,
                        pidData: pidDataTemplate,
                        enrichedObdData: []
                    },
                    () => {
                        this.props.openSnackbar('Emergency Success')
                        window.location.reload(false)
                    }
                )
            } else {
                this.props.openSnackbar('Some error occured')
            }
        }
    }

    /**
     * @function
     * @summary Fetch dtc codes
     */
    getDtcCodes = async () => {
        let response = await this.props.client.query({
            query: GET_DTC_CODES,
            variables: {
                clientLoginId: parseInt(localStorage.getItem('loginId'), 10),
                uniqueId: this.props.uniqueId
            }
        })

        if (response && response.data) {
            this.setState({dtcData: response.data.getDtcCodes})
        }
    }

    /**
     * @function
     * @summary Fetch obd data points
     */
    getObdDataPoints = async () => {
        let response = await this.props.client.query({
            query: GET_OBD_DATA_POINTS,
            variables: {
                uniqueId: this.props.uniqueId
            }
        })

        if (response.data && response.data.getObdDataPoints.length > 0) {
            this.correlateObdData(response.data)
        } else {
            this.setState({noObdDataPoints: true})
        }
    }

    /**
     * @function
     * @summary Filter and arrange the data
     */
    correlateObdData = data => {
        let enrichedObdData = this.state.pidData
        this.setState({obdData: data.getObdDataPoints}, () => {
            let obdData = data.getObdDataPoints[0]
            let obdDataArray = Object.keys(obdData)

            obdDataArray.forEach(pidName => {
                for (var index = 0; index < this.state.pidData.length; index++) {
                    // if (enrichedObdData[index].unit === '-') {
                    //   enrichedObdData[index].unit = ''
                    // }
                    if (
                        this.state.pidData[index].name.toLowerCase() ===
                        pidName.toLowerCase()
                    ) {
                        let obdValue = obdData[pidName]
                        if (
                            obdData[pidName] === 'NA' ||
                            obdData[pidName] === 'null' ||
                            obdData[pidName] === null
                        ) {
                            obdValue = '-'
                            enrichedObdData[index].unit = ''
                        }
                        if (obdValue.includes('[')) {
                            let stringArray = obdValue.split(';')
                            console.log(obdValue, stringArray)
                            obdValue = stringArray[1]
                        }
                        enrichedObdData[index].data = obdValue
                    }
                }
            })
        })

        let absData = []
        let emsData = []
        let n
        for (n = 0; n < enrichedObdData.length; n++) {
            if (
                enrichedObdData[n].name == 'front_speed' ||
                enrichedObdData[n].name == 'ecuinputsignals1' ||
                enrichedObdData[n].name == 'abseventactive' ||
                enrichedObdData[n].name == 'abswarninglamp' ||
                enrichedObdData[n].name == 'frontwheelspeedvalidity' ||
                enrichedObdData[n].name == 'rearwheelspeedvalidity' ||
                enrichedObdData[n].name == 'rear_speed'
            ) {
                absData.push(enrichedObdData[n])
            } else {
                emsData.push(enrichedObdData[n])
            }
        }
        this.setState({enrichedObdData: emsData})
        this.setState({enrichedAbsData: absData})

        // this.setState({ enrichedObdData: enrichedObdData })
    }

    handleClose = () => {
        this.getEmergencySupport()
        this.props.closeEmergencySupport()
    }

    handleChange = event => {
        this.setState({supportStatus: event.target.value})
    }

    handleRemarkChange = event => {
        this.setState({remarks: event.target.value})
    }

    handlePhoneChange = event => {
        this.setState({phone: event.target.value})
    }

    validatePhone() {
        const regex = new RegExp(/^(\+91[-\s]?)?[0]?(91)?[6789]\d{9}$/)
        if (this.state.phone) {
            if (regex.test(this.state.phone) === false) {
                this.props.openSnackbar('Invalid phone number')
            }
        }
    }

    render() {
        const {vehicleNumber, classes, open} = this.props
        return (
            <div>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Dialog
                            open={open}
                            onClose={this.handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            fullWidth
                            disableBackdropClick
                        >
                            <DialogTitle id="alert-dialog-title">
                                <Grid container spacing={2}>
                                    <Grid item xs={10}>
                                        <Typography variant="h6">
                                            Customer Support for {vehicleNumber}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText
                                    id="alert-dialog-description"
                                    style={{color:'#000'}}
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={1}>
                                                    <Tooltip title="Name" placement="bottom-start">
                                                        <PersonIcon color="primary"/>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <Typography>
                                                        {this.state.emergencyDetails?.fName ?? "N/A"}
                                                        {" "}
                                                        {this.state.emergencyDetails?.lName ?? " "}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={1}>
                                                    <Tooltip title="Address" placement="bottom-start">
                                                        <HomeIcon color="primary"/>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <Typography>
                                                        {this.state.emergencyDetails?.address?.address1 ?? "N/A"}
                                                        {" "}
                                                        {this.state.emergencyDetails?.address?.address2 && `${this.state.emergencyDetails.address.address2}, `}
                                                        {this.state.emergencyDetails?.address?.city && `${this.state.emergencyDetails.address.city}, `}
                                                        {this.state.emergencyDetails?.address?.region && `${this.state.emergencyDetails.address.region}, `}
                                                        {this.state.emergencyDetails?.address?.country && `${this.state.emergencyDetails.address.country} - `}
                                                        {this.state.emergencyDetails?.address?.zip ?? ""}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={1}>
                                                    <Tooltip
                                                        title="Contact number"
                                                        placement="bottom-start"
                                                    >
                                                        <ContactPhoneIcon color="primary"/>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <Tooltip
                                                        title="Contact number"
                                                        placement="bottom-start"
                                                    >
                                                        <Typography>
                                                            {
                                                                this.state.emergencyDetails ?
                                                                    this.state.emergencyDetails.primaryCallingCode != null ?
                                                                        this.state.emergencyDetails.primaryCallingCode + " - " : "" : "N/A"
                                                            } {
                                                            this.state.emergencyDetails &&
                                                            this.state.emergencyDetails.primaryNumber != null ?
                                                                this.state.emergencyDetails.primaryNumber : ""
                                                        }
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={1}>
                                                    <Tooltip
                                                        title="Emergency contact number"
                                                        placement="bottom-start"
                                                    >
                                                        <PhoneIcon color="primary"/>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <Tooltip
                                                        title="Emergency contact number"
                                                        placement="bottom-start"
                                                    >
                                                        <Typography>
                                                            {
                                                                this.state.emergencyDetails ?
                                                                    this.state.emergencyDetails.primaryCallingCode != null ?
                                                                        this.state.emergencyDetails.primaryCallingCode + " - " : "" : "N/A"
                                                            } {
                                                            this.state.emergencyDetails &&
                                                            this.state.emergencyDetails.primaryNumber != null ?
                                                                this.state.emergencyDetails.primaryNumber : ""
                                                        }
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={1}>
                                                    <Tooltip title="Location" placement="bottom-start">
                                                        <LocationOnIcon color="primary"/>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography>
                                                        Latitude:{' '}
                                                        {this.state.emergencyData &&
                                                        this.state.emergencyData.getEmergencySupport.lat !=
                                                        null
                                                            ? this.state.emergencyData.getEmergencySupport.lat
                                                            : 'N/A'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography>
                                                        {' '}
                                                        Longitude:{' '}
                                                        {this.state.emergencyData &&
                                                        this.state.emergencyData.getEmergencySupport.long !=
                                                        null
                                                            ? this.state.emergencyData.getEmergencySupport
                                                                .long
                                                            : 'N/A'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <FormControl style={{minWidth: 130}}>
                                                        <InputLabel id="demo-simple-select-label">
                                                            Support Status
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="supportStatus"
                                                            value={this.state.supportStatus}
                                                            onChange={this.handleChange}
                                                        >
                                                            {this.state.supportAlerts
                                                                ? this.state.supportAlerts.getAllAlertSupportMaster.map(
                                                                    option => (
                                                                        <MenuItem
                                                                            key={option.id}
                                                                            value={option.id}
                                                                        >
                                                                            {option.supportStatus}
                                                                        </MenuItem>
                                                                    )
                                                                )
                                                                : null}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        id="remarks"
                                                        label="Remarks"
                                                        placeholder="Please fill the remarks"
                                                        variant="outlined"
                                                        multiline
                                                        rows={4}
                                                        value={this.state.remarks}
                                                        onChange={this.handleRemarkChange}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </DialogContentText>
                                <DialogActions>
                                    <Grid container justify="flex-end" spacing={2}>
                                        <Grid item>
                                            <Button
                                                onClick={this.handleClose}
                                                color="primary"
                                                variant="outlined"
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                onClick={this.addSupportStatus}
                                                color="primary"
                                                variant="contained"
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                                <DialogContentText>
                                    <Grid container>
                                        <Grid item>
                                            <ExpansionPanel>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon/>}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>EMS OBD PARAMETERS</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Grid container spacing={2}>
                                                        {!this.state.noObdDataPoints ? (
                                                            this.state.enrichedObdData.length > 0 ? (
                                                                this.state.enrichedObdData.map(obdCardData => (
                                                                    <Grid item sm={3}>
                                                                        <ValueCard
                                                                            alertName={obdCardData.description}
                                                                            value={`${obdCardData.data} ${obdCardData.unit}`}
                                                                            alertDescription={obdCardData.description}
                                                                            alertCount={0}
                                                                            cardColor={'#F7B733'}
                                                                        />
                                                                    </Grid>
                                                                ))
                                                            ) : (
                                                                <CircularProgress/>
                                                            )
                                                        ) : (
                                                            <Typography>No data found</Typography>
                                                        )}
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>
                                    </Grid>
                                    <br/>
                                    <Grid container>
                                        <Grid item>
                                            <ExpansionPanel>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon/>}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>ABS OBD PARAMETERS</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Grid container spacing={2}>
                                                        {!this.state.noObdDataPoints ? (
                                                            this.state.enrichedAbsData.length > 0 ? (
                                                                this.state.enrichedAbsData.map(obdCardData => (
                                                                    <Grid item sm={3}>
                                                                        <ValueCard
                                                                            alertName={obdCardData.description}
                                                                            value={`${obdCardData.data} ${obdCardData.unit}`}
                                                                            alertDescription={obdCardData.description}
                                                                            alertCount={0}
                                                                            cardColor={'#F7B733'}
                                                                        />
                                                                    </Grid>
                                                                ))
                                                            ) : (
                                                                <CircularProgress/>
                                                            )
                                                        ) : (
                                                            <Typography>No data found</Typography>
                                                        )}
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>
                                    </Grid>
                                    <br/>
                                    <Grid container>
                                        <Grid item>
                                            <ExpansionPanel>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon/>}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>DTC Snapshot</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Grid container spacing={2}>
                                                        {this.state.dtcData ? (
                                                            this.state.dtcData.length > 0 ? (
                                                                this.state.dtcData.map(data => (
                                                                    <Grid item sm={6}>
                                                                        <ValueCard
                                                                            alertName={data.dtcData[0].dtcCode}
                                                                            value={data.dtcData[0].dtcCode}
                                                                            alertDescription={
                                                                                data.dtcData[0].dtcDescription
                                                                            }
                                                                            alertCount={0}
                                                                            cardColor={'#F7B733'}
                                                                        />
                                                                    </Grid>
                                                                ))
                                                            ) : (
                                                                <Typography>No data found</Typography>
                                                            )
                                                        ) : (
                                                            <CircularProgress/>
                                                        )}
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>
                                    </Grid>
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(style)(withSharedSnackbar(withApollo(CustomerSupport)))
