import React from "react";
import Live from "../../pages/LiveVehicle/Live";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";
import gql from "graphql-tag";
import {useQuery} from "react-apollo";
import {CircularProgress} from "@material-ui/core";

const GET_TOKEN = gql`
  query Query($uniqueId: String!, $ruleValue: String!) {
  getValidLiveShareToken(uniqueId: $uniqueId, ruleValue: $ruleValue)
}
`

function HomePage(props) {
    const uniqueId = localStorage.getItem('vehicle');
    const ruleValue = localStorage.getItem('ruleValue');
    const token = localStorage.getItem('token');
    const {data, loading} = useQuery(GET_TOKEN, {
        variables: {
            uniqueId,
            ruleValue
        }
    });

    if (loading) {
        return (
            <div
                style={{
                    width: '90vw',
                    height: '90vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                <CircularProgress/>
            </div>
        )
    }

    if (data?.getValidLiveShareToken && token) {
        return <Live {...props}/>;
    } else {
        return <ErrorPage {...props} />;
    }
}

export default HomePage;
