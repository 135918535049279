import React, { Suspense, Component } from "react";
/* eslint-disable import/first */
const Map = React.lazy(() => import("../../Map/Map"));
// import Map from '../../Map/Map'
import { DEVICE_LOCATION } from "../../graphql/subscriptions";
import withGoogleMaps from "../../hoc/withGoogleMaps";
import RemoteDiagnostics from '../RemoteDiagnostic/RemoteDiagnostic'
import {
  Grid,
  Paper,
  Typography,
  withStyles,
  Divider,
  Button, CircularProgress
} from "@material-ui/core";

import gql from "graphql-tag";
import moment from "moment";

import getCustomMarker from "../../Map/MapUtils/CustomMarker";
import getMultiLine from "../../Map/MapUtils/MultiLine";
// import { withApollo } from 'react-apollo'
import NavBar from "../NavBar/NavBar";
import { withApollo } from "react-apollo";
import hospital from '../../static/png/hospital.png'
import servicecenter from '../../static/svg/servicecenter.svg'
import CustomerSupport from "../../components/CustomerSupport";

const GET_VEHICLE_DETAIL = gql`
  query($id: String) {
    getVehicleDetail(deviceUniqueId_fk: $id) {
      vehicleType
    }
  }
`;

const GET_LATEST_LOCATION = gql`
  query($id: String!) {
    latestLocation: getDeviceLatestLocation(deviceId: $id) {
      latitude
      longitude
      vehicleNumber
      address
      speed
      timestamp
      extBatVol
      haltStatus
      idlingStatus
      isNoGps
      isOffline
      isNoData
    }
  } 
`;

const styles = (theme) => ({
  root: {
    height: "100vh",
    overflow: "hidden",
    backgroundColor:"black"
  },
  appBar: {
    zIndex: theme.zIndex.drawer,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  flex: {
    flex: 1,
  },
});

/**
 * @summary Custom marker object
 */
let CustomMarker;
/**
 * @summary Custom multicolour polyline object
 */
let MultiLine;

/**
 * @summary Index to monitor progress of animation
 */
let count = 0;
/**
 * @summary Vehicle icon
 */
let markerInstance = null;
/**
 * @summary Animation loop
 */
let loop = null;
/**
 * @summary Flag to monitor if animation is active
 */
let isAnimationActive = false;

// let dId = localStorage.getItem('vehicle')

class Live extends Component {
  constructor(props) {
    super(props);
    CustomMarker = getCustomMarker(props.google);
    MultiLine = getMultiLine(props.google);
    // CustomPopup = getCustomPopup(props.google)
    // this._customPopup = new CustomPopup()
    // this.state = {
    // 	map: null
    // }
  }

  /**
   * @property {?object} map  Map object
   * @property {string} vehicleNumber vehicle number
   * @property {string} speed vehicle speed
   * @property {string} time last tracked time
   * @property {string} location last tracked location
   * @property {boolean} isLiveTracking
   */
  state = {
    map: null,
    vehicleNumber: "",
    speed: "0",
    time: "",
    location: "",
    isLiveTracking: false,
    id: localStorage.getItem("vehicle"),
    expired: false,
    remoteDiagnosticPopUp: false,
    allHospitalMarkers: [],
    emergency: false,
  };

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    this.resetLiveTracking();
    this.stopSubscription();
    this.clearMapVariable();
  }

  setMap = (map) =>
    this.setState({ map }, () => {
      this.setState({ multiLine: new MultiLine(this.state.map) });
      this.handleRequestLive();
    });
  // ** LIVE TRACKING FUNCTIONS ** //-------------------------------------------------------------------
  isOffline = (timestamp) => {
    // timestamp is assumed to be UTC+0
    var d = new Date();
    var currentTime = Math.round(d.getTime() / 1000);
    return currentTime - parseInt(timestamp) > 1800;
  };

  setupSubscription = () => {
    // console.log("setup sub", this.props);
    this.deviceSubscription = this.props.client.subscribe({
      query: DEVICE_LOCATION,
      variables: {
        deviceId: this.state.id,
        snapToRoad: true,
      },
      fetchPolicy: "network-only",
      errorPolicy: "all",
    });
  };

  resetLiveTracking = () => {
    isAnimationActive = false;
    this.eta = [];
    count = 0;
    this.setState({
      livedata: {
        device: [],
        pointsReceived: 0,
      },
    });
    this.breakTimeout();
  };

  animateLive = () => {
    // console.log("amination", this.state.liveData);

    const device = this.state.liveData.device;
    const pointsReceived = this.state.liveData.pointsReceived;
    if (markerInstance && device && pointsReceived > 0) {
      this.setState({
        speed: device[count].speed,
        time: device[count].timestamp,
        location: device[count].address,
      });
      // console.log("device[count]", device[count]);
      markerInstance.updateMarker(
        {
          lat:
            device[count].latitude != null
              ? parseFloat(device[count].latitude.toFixed(6))
              : "12.0989876",
          lng:
            device[count].longitude != null
              ? parseFloat(device[count].longitude.toFixed(6))
              : "77",
        },
        {
          /* eslint-disable indent */
          status: this.isOffline(device[count].timestamp)
            ? "offline"
            : device[count].isNoGps
            ? "nogps"
            : device[count].haltStatus
            ? "halt"
            : device[count].idlingStatus === true
            ? "idle"
            : device[count].idlingStatus === false &&
              device[count].haltStatus === false
            ? "running"
            : "default",
          mode: "live",
          // isOverspeed: device[count].isOverspeed,
          timestamp: device[count].timestamp,
          speed: device[count].speed,
          /* eslint-enable indent */
        },
        10000 / pointsReceived // Calculate interval when snapped points are received
      );
      if (count < pointsReceived - 1) {
        count = count + 1;
        loop = setTimeout(this.animateLive, 10000 / pointsReceived);
      } else {
        this.resetLiveTracking();
      }
    }
  };

  startSubscription = () => {
    // console.log("startSubscription here");
    this.setState({ isLiveTracking: true });
    this.unsubHandle = this.deviceSubscription.subscribe({
      next: ({ data }) => {
        this.setState({
          speed: data.deviceLiveTracking.speed,
          location: data.deviceLiveTracking.address,
          time: data.deviceLiveTracking.timestamp,
        });
        if (isAnimationActive) {
          this.resetLiveTracking();
          this.setState(
            {
              liveData: {
                device: data.deviceLiveTracking,
                pointsReceived: data.deviceLiveTracking.length,
              },
            },
            () => {
              count = 0;
              if (this.state.map) this.animateLive();
            }
          );
        } else {
          //   console.log("data", data);

          this.setState(
            {
              liveData: {
                device: data.deviceLiveTracking,
                pointsReceived: data.deviceLiveTracking.length,
              },
            },
            () => {
              count = 0;
              if (this.state.map) this.animateLive();
            }
          );
        }
      },
    });
    // console.log("Show Hospitals Called Here");
    this.showHospitals()
  };

  stopSubscription = () => {
    this.setState({ isLiveTracking: false });
    if (this.unsubHandle) this.unsubHandle.unsubscribe();
  };

  handleRequestLive = async () => {
    this.setupSubscription();

    // Vehicle type for marker icon
    const response = await this.props.client.query({
      query: GET_VEHICLE_DETAIL,
      variables: {
        id: this.state.id,
      },
      errorPolicy: "all",
    });
    let vehicleType = "car";
    if (response.data) {
      vehicleType = response.data.getVehicleDetail.vehicleType;
    }

    // Get latest location before subscription to show marker
    let latitude = null;
    let longitude = null;
    const location = await this.props.client.query({
      query: GET_LATEST_LOCATION,
      variables: {
        id: this.state.id,
      },
    });

    let isOffline;
    let isNoGps;
    let haltStatus;
    let idlingStatus;
    let isNoData;

    if (location.data) {
      // console.log("location.data", location.data);
      latitude = location.data.latestLocation.latitude;
      longitude = location.data.latestLocation.longitude;
      isOffline = location.data.latestLocation.isOffline;
      isNoGps = location.data.latestLocation.isNoGps;
      haltStatus = location.data.latestLocation.haltStatus;
      idlingStatus = location.data.latestLocation.idlingStatus;
      isNoData = location.data.latestLocation.isNoData;
      this.setState({
        liveData: {
          device: [
            {
              latitude: location.data.latestLocation.latitude,
              longitude: location.data.latestLocation.longitude,
            },
          ],
          pointsReceived: 1,
        },
        vehicleNumber: location.data.latestLocation.vehicleNumber,
        speed: location.data.latestLocation.speed,
        time: location.data.latestLocation.timestamp,
        location: location.data.latestLocation.address,
      });
    }

    // latitude ? latitude :
    //  longitude ? longitude :

    // Live marker
    markerInstance = new CustomMarker(
      {
        uniqueId: this.state.id,
        // latitude: "12.0988876",
        // longitude: "77.09887665",
        latitude,
        longitude,
        timestamp: moment().unix(),
        speed: 0,
        vehicleType: vehicleType,
        isOffline: isOffline,
        isNoGps: isNoGps,
        haltStatus: haltStatus,
        idlingStatus: idlingStatus,
        isNoData: isNoData,
      },
      this.state.map,
      this.state.multiLine
    );

    // Move to vehicle being tracked
    this.state.map.setCenter(
      new this.props.google.maps.LatLng(latitude, longitude)
    );
    // console.log("Before Start Subscription is Called");
    // Start subscription for live tracking
    this.startSubscription();
  };

  handleCancelLiveTracking = () => {
    this.resetLiveTracking();
    this.stopSubscription();
    this.clearMapVariable();
  };

  clearMapVariable = () => {
    if (this.state.multiLine instanceof MultiLine) {
      this.state.multiLine.remove();
    }
    if (markerInstance) markerInstance.setMap(null);
    markerInstance = null;
  };

  breakTimeout = () => {
    if (loop) {
      clearTimeout(loop);
      loop = null;
    }
  };

  remoteDiagnosticHandler = () => {
    this.setState({remoteDiagnosticPopUp:true})

  }

  closeRemoteDiagnosticsHandler = () => {
    this.setState({remoteDiagnosticPopUp:false})
  }

  //check token
  checkToken = () => {
    // do whatever you like here

    if (!localStorage.getItem("token"))
    {
      this.setState({ expired: true });
      //   localStorage.clear();
      this.stopSubscription();
    }
    setTimeout(this.checkToken, 5000);
  };

  // Function to initialise the hospital and showroom /dealers markers on the map
  showHospitals = () => {
    // console.log("initialising the hospital and showroom /dealers markers on the map");
    this.addHospitalMarker();
    this.addShowroomMarker();
  }

  // Function to add hospital markers to the map
  addHospitalMarker = () => {
    const hospitalIcon = {
      url: hospital,
      scaledSize: new this.props.google.maps.Size(30, 30),
      anchor: new this.props.google.maps.Point(0, 30),
    };

    const service = new this.props.google.maps.places.PlacesService(this.state.map);
    const pyrmont = new this.props.google.maps.LatLng(
      this.state.liveData.device[0].latitude,
      this.state.liveData.device[0].longitude
    );

    const request = {
      location: pyrmont,
      radius: 10000,
      type: ['hospital'],
    };

    service.nearbySearch(request, (results, status) => {
      if (results && status === 'OK') {
        const len = Math.min(results.length, 10);

        results.slice(0, len).forEach((result) => {
          const name = result.name;
          const address = result.vicinity;

          const phoneNumberPromise = new Promise((resolve) => {
            const detailRequest = {
              placeId: result.place_id,
              fields: ['formatted_phone_number'],
            };
            service.getDetails(detailRequest, (place, detailStatus) => {
              if (detailStatus === 'OK' && place && place.formatted_phone_number) {
                resolve(place.formatted_phone_number);
              } else {
                resolve('');
              }
            });
          });

          phoneNumberPromise.then((phoneNumber) => {
            const srcLocation = new this.props.google.maps.LatLng(
              this.state.liveData.device[0].latitude,
              this.state.liveData.device[0].longitude
            );

            const dstLocation = result.geometry.location;

            const distance = this.props.google.maps.geometry.spherical.computeDistanceBetween(
              srcLocation,
              dstLocation
            );

            const simplifiedDistance = (distance / 1000).toFixed(2);

            const position = {
              lat: result.geometry.location.lat(),
              lng: result.geometry.location.lng(),
            };

            const hospitalMarker = new this.props.google.maps.Marker({
              position: position,
              map: this.state.map,
              title: name,
              optimized: false,
              icon: hospitalIcon,
            });

            hospitalMarker.addListener('click', (e) => {
              const infoWindow = new this.props.google.maps.InfoWindow();
              infoWindow.setPosition(position);
              infoWindow.open(this.state.map);

              const content = `
                <div style="color: black;">
                  <span>Hospital</span> <br />
                  <b>${name}</b><br />
                  <span>${address}</span><br /><br />
                  <span><b>Phone number: ${phoneNumber || 'N/A'}</b></span><br /><br />
                  <span><b>Distance from you: ${simplifiedDistance} km</b></span>
                </div>
              `;
              infoWindow.setContent(content);
            });

            this.state.allHospitalMarkers.push(hospitalMarker);
          });
        });
      }
    });
  };

  // Function to add showroom/service center markers to the map
  addShowroomMarker = async () => {
    const bikeIcon = {
      url: servicecenter,
      scaledSize: new this.props.google.maps.Size(70, 70),
      anchor: new this.props.google.maps.Point(0, 30)
    };

    const infoWindow = new this.props.google.maps.InfoWindow();

    try {
      const response = await fetch(
        `https://api.royalenfield.com/v3/core/prime/dealers/dealers-around-me?longitude=${this.state.liveData.device[0].longitude}&latitude=${this.state.liveData.device[0].latitude}&distance=100`
      );

      if (!response.ok) {
        throw new Error('Failed to fetch showroom data');
      }

      const data = await response.json();
      const dealerData = data.data.slice(0, 10);

      dealerData.forEach((specificDealer) => {
        const name = specificDealer.dealerName;
        const address = `${specificDealer.addressLine1},${specificDealer.addressLine2},${specificDealer.addressLine3},${specificDealer.landmark},${specificDealer.Locality},${specificDealer.state}`;
        const phoneNumber = `${specificDealer.phone}, ${specificDealer.altPhone}`;
        const distance = `${specificDealer.distance}`;

        const position = {
          lat: specificDealer.latitude,
          lng: specificDealer.longitude
        };

        const showroomMarker = new this.props.google.maps.Marker({
          position: position,
          map: this.state.map,
          title: name,
          optimized: false,
          icon: bikeIcon
        });

        showroomMarker.addListener('click', () => {
          infoWindow.setPosition(position);
          infoWindow.open(this.state.map);

          const content = `
            <div style="color: black;">
              <span>Showroom/Service-Centre</span> <br />
              <b>${name}</b><br />
              <span>${address}</span><br /><br />
              <span><b>Distance from you: ${distance} km</b></span><br/><br/>
              <span><b>Phone numbers</b></span><br/>
              <span>${phoneNumber}</span>
            </div>
          `;
          infoWindow.setContent(content);
        });

        this.state.allHospitalMarkers.push(showroomMarker);
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  openEmergencySupport = () => {
    this.setState({ emergency: true })
  }

  closeEmergencySupport = () => {
    this.setState({ emergency: false })
  }


  // ** LIVE TRACKING FUNCTIONS ** //-------------------------------------------------------------------
  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <NavBar {...this.props} />
            </Grid>
          </Grid>
          <br />
          <br />
          {!this.state.expired ? (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <Suspense fallback={<div><CircularProgress/></div>}>
                  <Map
                    google={this.props.google}
                    setMap={this.setMap}
                    zoom={16}
                  />
                </Suspense>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <br />
                <Paper
                  elevation={2}
                  style={{
                    // height: 450,
                    borderRadius: "10px",
                    backgroundColor:"#121212"
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        textAlign: "center",
                        color:"#ffff"
                      }}
                    >
                      <Typography variant="h5">Information</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        margin: "10px",
                        color:"#ffff"
                      }}
                    >
                      <Typography
                        variant="body2"
                        gutterBottom
                      >
                        Vehicle Number
                      </Typography>
                      <Typography variant="subtitle1">
                        {this.state.vehicleNumber
                          ? this.state.vehicleNumber
                          : "N/A"}
                      </Typography>
                      <Divider />
                      <br />
                      <Typography
                        variant="body2"
                        gutterBottom
                      >
                        Address
                      </Typography>
                      <Typography variant="subtitle1">
                        {this.state.location ? this.state.location : "N/A"}
                      </Typography>
                      <Divider />
                      <br />
                      <Typography
                        variant="body2"
                        gutterBottom
                      >
                        Speed (km/hr)
                      </Typography>
                      <Typography variant="subtitle1" >
                        {this.state.speed}
                      </Typography>
                      <Divider />
                      <br />
                      <Typography
                        variant="body2"
                        gutterBottom
                      >
                        Last tracked time
                      </Typography>
                      <Typography variant="subtitle1">
                        {this.state.time
                          ? moment
                              .unix(this.state.time)
                              .format("Do MMMM YYYY h:mm:ss A")
                          : "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Grid container alignItems="space-between">
                    <Grid item>
                      <Button onClick={this.remoteDiagnosticHandler} style={{backgroundColor:"red",color:"#ffff"}}>Remote Diagnostics</Button>
                    </Grid>
                    <Grid item>
                      <Button
                          style={{backgroundColor:"red",color:"#ffff", marginLeft: '12px'}}
                          onClick={this.openEmergencySupport}
                      >
                        Customer Support
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {this.state.remoteDiagnosticPopUp && (
                <RemoteDiagnostics
                vehicle={this.state.id}
                open={this.state.remoteDiagnosticPopUp}
                closeRemoteDiagnostics={this.closeRemoteDiagnosticsHandler}
              />
              )}
              {this.state.emergency && (
                  <CustomerSupport
                      vehicleNumber={this.state.vehicleNumber}
                      uniqueId={this.state.id}
                      open={this.state.emergency}
                      closeEmergencySupport={this.closeEmergencySupport}
                  />
              )}
            </Grid>
          ) : (
              <div
                  style={{
                    textAlign: "center",
                  }}
              >
                <h1>Sorry !!! Token expired.</h1>
              </div>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withApollo(withGoogleMaps(withStyles(styles)(Live)));
