/**
 * @module RemoteDiagnostics
 * @summary This module exports the RemoteDiagnostics component
 */
 import React, { Component } from 'react'
 import gql from 'graphql-tag'
 import { Query, withApollo } from 'react-apollo'
 import {
   Grid,
   withStyles,
   Typography,
   Paper,
   Card,
   CardContent,
   CardHeader,
   Divider,
   CircularProgress,
   Tooltip,
   Zoom
 } from '@material-ui/core'
 import Button from '@material-ui/core/Button'
 import Dialog from '@material-ui/core/Dialog'
 import DialogActions from '@material-ui/core/DialogActions'
 import DialogContent from '@material-ui/core/DialogContent'
 import DialogContentText from '@material-ui/core/DialogContentText'
 import DialogTitle from '@material-ui/core/DialogTitle'
 import ValueCard from '../ValueCard/ValueCard'
//  import withSharedSnackbar from '@zeliot/common/hoc/withSharedSnackbar'
//  import { THEME_MAIN_COLORS as COLOR_RANGE } from '@zeliot/common/constants/styles'
 import getFormattedTime from '../../getFormattedTime'
 import InfoIcon from '@material-ui/icons/Info'

 const COLOR_RANGE = {
  sunshine: '#F7B733'
 }


 const GET_OBD_DATA_POINTS = gql`
   query getObdDataPoints($uniqueId: String!) {
     getObdDataPoints(uniqueId: $uniqueId) {
       ts
       lat
       lng
       fuel_pump_status
       injection_mode
       ignition_mode
       mil_state
       rpm
       engine_state
       idle_speed
       timer_inclination
       cp_mode
       isc_activation
       input_battery_voltage
       tilt_switch
       engine_stop_request
       VB_Snapshot
       intakeAirTemp
       maf_throttle
       maf
       map_throttle
       imap
       V_TPS_BAS
       TPS_DEG
       raw_angle
       oxy_sensor_voltage
       lambda_setpoint
       inj_timing
       coolant
       vehicleSpeed
       VB_MMV
       mffi_homogeneous_mode
       atmospheric_pressure
       V_TPS_AD_BOL
       ISAPWM_AD_MMV
       TI_HOM_INJ
       IGA_CYL
       MFF_ADAP_ADD
       MFF_ADAP_FAC
       idle_charge
       IGA_ISC
       TI_MES_INJ
       clutch_state
       duty_cycle
       ERT
       tilt_switch_number
       TILT_AD_DGNC
       obdDistance
       bank_angle
       tankLevel
       external_bat_voltage
       pluscode
       ecuinputsignals1
       abseventactive
       abswarninglamp
       front_speed
       frontwheelspeedvalidity
       rear_speed
       rearwheelspeedvalidity
       tcuextbatvol
     }
   }
 `
 
 const GET_DTC_CODES = gql`
   query getDtcCodes($clientLoginId: Int!, $uniqueId: String) {
     getDtcCodes(clientLoginId: $clientLoginId, uniqueId: $uniqueId) {
       uniqueId
       dtcData {
         ts
         lat
         lng
         dtcCode
         dtcDescription
       }
     }
   }
 `
 
 const GET_SUPPORT_PIDS = gql`
   query getSupportedPids($uniqueId: String!) {
     getSupportedPids(uniqueId: $uniqueId) {
       name
       description
       unit
     }
   }
 `
 
 const pidDataTemplate = [
   { name: 'fuel_pump_status', description: 'Electrical Fuel Pump', unit: '' },
   { name: 'injection_mode', description: 'Injection Mode', unit: '' },
   { name: 'ignition_mode', description: 'Ignition Mode', unit: '' },
   { name: 'mil_state', description: 'MIL State', unit: '' },
   { name: 'rpm', description: 'Engine Speed', unit: 'rpm' },
   { name: 'engine_state', description: 'Engine Operating State', unit: '' },
   { name: 'idle_speed', description: 'Target Idle speed', unit: 'rpm' },
   {
     name: 'timer_inclination',
     description: 'Timer Of Vehicle Inclinaison Exceeds The Threshold',
     unit: 's'
   },
   { name: 'cp_mode', description: 'CP Mode', unit: '' },
   { name: 'isc_activation', description: 'ISC Activation', unit: '' },
   {
     name: 'input_battery_voltage',
     description: 'Battery Voltage Input',
     unit: 'V'
   },
   { name: 'tilt_switch', description: 'Tilt Switch Detection', unit: '' },
   {
     name: 'engine_stop_request',
     description: 'Engine Stop Request Cause Of Tilt Detection',
     unit: ''
   },
   { name: 'vb_snapshot', description: 'VB_Snapshot', unit: 'V' },
   { name: 'intakeairtemp', description: 'Intake Air Temperature', unit: '°C' },
   {
     name: 'maf_throttle',
     description: 'Air-Mass Flow At The Throttle',
     unit: 'mg/tdc'
   },
   { name: 'maf', description: 'Air-Mass Flow', unit: 'mg/tdc' },
   {
     name: 'map_throttle',
     description: 'Manifold Air Pressure Upstream Throttle Value',
     unit: 'mb'
   },
   { name: 'imap', description: 'MAP Value By Cylinder', unit: 'mb' },
   {
     name: 'v_tps_bas',
     description: 'V_TPS_BAS,Signal voltage of throttle flap positoion sensor 1',
     unit: 'V'
   },
   {
     name: 'tps_deg',
     description: 'TPS_DEG,Throttle flap opening angle with high resolution',
     unit: '°TPS'
   },
   {
     name: 'raw_angle',
     description: 'Raw Angle From The Potentiometer',
     unit: '°TPS'
   },
   {
     name: 'oxy_sensor_voltage',
     description: 'Upstream Oxygen Sensor Voltage',
     unit: 'V'
   },
   { name: 'lambda_setpoint', description: 'Lambda Setpoint', unit: '' },
   { name: 'inj_timing', description: 'Corrected Inj. Timing', unit: '%' },
   { name: 'coolant', description: 'Coolant Temperature', unit: '°C' },
   { name: 'vehiclespeed', description: 'Vehicle Speed', unit: 'km/h' },
   {
     name: 'vb_mmv',
     description: 'Moving Mean Value Of Battery Voltage',
     unit: 'V'
   },
   {
     name: 'mffi_homogeneous_mode',
     description: 'Mass fuel flow injected for Homogeneous Mode',
     unit: 'mg/tdc'
   },
   {
     name: 'atmospheric_pressure',
     description: 'Atmospheric Pressure',
     unit: 'mb'
   },
   {
     name: 'v_tps_ad_bol',
     description:
       'V_TPS_AD_BOL,Saved voltage value for minimum position of the potentiometer 1',
     unit: 'V'
   },
   {
     name: 'isapwm_ad_mmv',
     description:
       'ISAPWM_AD_MMV,Adaptive value of integral idle speed regulation term',
     unit: '%'
   },
   {
     name: 'ti_hom_inj',
     description: 'TI_HOM_INJ,Fuel injection time for homogeneous mode',
     unit: 'ms'
   },
   {
     name: 'iga_cyl',
     description: 'IGA_CYL, Global Ignition angle applied at cylinder 0',
     unit: '°CRK'
   },
   {
     name: 'mff_adap_add',
     description: 'MFF_ADAP_ADD, Relative additive adaptation factor',
     unit: 'mg/tdc'
   },
   {
     name: 'mff_adap_fac',
     description: 'MFF_ADAP_FAC, Relative multiplicative adaptation factor',
     unit: '%'
   },
   {
     name: 'idle_charge',
     description: 'Calculated Idle Charge Actuator Opening',
     unit: '%'
   },
   {
     name: 'iga_isc',
     description: 'IGA_ISC,PI feedback control for ignition angle',
     unit: '°CRK'
   },
   {
     name: 'ti_mes_inj',
     description: 'TI_MES_INJ, Mesured fuel time delivery during cylinder cycle',
     unit: 'ms'
   },
   { name: 'clutch_state', description: 'Clutch State', unit: '' },
   {
     name: 'duty_cycle',
     description: 'Corrected Duty Cycle Of PWM Signal For CPS Opening',
     unit: '%'
   },
   { name: 'ert', description: 'ERT', unit: '' },
   {
     name: 'tilt_switch_number',
     description: 'Number Of Tilt Switch Detection',
     unit: ''
   },
   {
     name: 'tilt_ad_dgnc',
     description: 'TILT_AD_DGNC, Adaptive value of vehicle inclinaison by DGNC',
     unit: '°'
   },
   { name: 'obddistance', description: 'Odometer', unit: 'km' },
   { name: 'external_bat_voltage', description: 'Battery Voltage', unit: 'V' },
   { name: 'tanklevel', description: 'Fuel Level', unit: '%' },
   {
     name: 'bank_angle',
     description: 'Bank angle',
     unit: '°'
   },
   {
     name: 'ecuinputsignals1',
     description: 'ABS ECU Input Signals',
     unit: ''
   },
   {
     name: 'abseventactive',
     description: 'ABS Event Active',
     unit: ''
   },
   {
     name: 'abswarninglamp',
     description: 'ABS Warning Lamp',
     unit: ''
   },
   {
     name: 'front_speed',
     description: 'ABS Front Speed',
     unit: 'km/h'
   },
   {
     name: 'frontwheelspeedvalidity',
     description: 'ABS Front Wheel Speed Validity',
     unit: ''
   },
   {
     name: 'rear_speed',
     description: 'ABS Rear Speed',
     unit: 'km/h'
   },
   {
     name: 'rearwheelspeedvalidity',
     description: 'ABS Rear Wheel Speed Validity',
     unit: ''
   },
   {
     name: 'tcuextbatvol',
     description: 'TCU External Battery Voltage',
     unit: 'V'
   }
 ]
 
 const style = theme => ({
   searchClass: {
     marginBottom: '10px',
     overflow: 'visible'
   },
   textLeft: {
     textAlign: 'left'
   },
   formControl: {
     margin: theme.spacing(1),
     minWidth: 120
   },
 
   paper: {
     height: 60,
     width: 160,
     background: 'yellow',
     margin: '2px',
     color: 'black'
   }
 })
 
 /**
  * @summary RemoteDiagnostics component renders Remote Diagnostics with snapshot
  */
 class RemoteDiagnostics extends Component {
   constructor() {
     super()
     this.state = {
       obdData: null,
       dtcData: null,
       pidData: pidDataTemplate,
       enrichedObdData: [],
       enrichedAbsData: [],
       obdFetchedTime: null,
       obdLoading: false
     }
   }
 
   componentDidMount = async () => {
     // await this.getSupportedPids()
     this.getObdDataPoints()
     this.getDtcCodes()
     await this.setupPolling()
     this.startPolling()
   }
 
   handleClose = () => {
     this.props.closeRemoteDiagnostics()
   }
 
   /**
    * @function
    * @summary Setup for polling
    */
   setupPolling = () => {
     console.log('this.props.vehicle', this.props.vehicle.uniqueId)
     this.allDevicesQuery = this.props.client.watchQuery({
       query: GET_OBD_DATA_POINTS,
       variables: {
         uniqueId: localStorage.getItem('vehicle')
       },
       pollInterval: 30000
     })
 
     this.dtcDataPoll = this.props.client.watchQuery({
       query: GET_DTC_CODES,
       variables: {
         clientLoginId: parseInt(localStorage.getItem('loginId'), 10),
         uniqueId: localStorage.getItem('vehicle')
       },
       pollInterval: 30000
     })
   }
 
   /**
    * @function
    * @summary Polling for obd data
    */
   startPolling = () => {
     this.allDevicesQuery.subscribe({
       next: ({ data }) => {
         this.correlateObdData(data)
       }
     })
 
     this.dtcDataPoll.subscribe({
       next: ({ data }) => {
         this.setState({ dtcData: data.getDtcCodes })
       }
     })
   }
 
   stopPolling = () => this.allDevicesQuery.stopPolling()
   stopPolling = () => this.dtcDataPoll.stopPolling()
 
   /**
    * @function
    * @summary Function to get obd data points
    */
   getObdDataPoints = async () => {
     this.setState({ obdLoading: true })
     let response = await this.props.client.query({
       query: GET_OBD_DATA_POINTS,
       variables: {
         uniqueId: localStorage.getItem('vehicle')
       }
     })
     if (response.data) {
       this.setState({ obdLoading: false }, () => {
         this.correlateObdData(response.data)
       })
     }
   }
 
   correlateObdData = data => {
     let enrichedObdData = this.state.pidData
     this.setState(
       {
         obdData: data.getObdDataPoints,
         obdFetchedTime: data.getObdDataPoints[0].ts
       },
       () => {
         let obdData = data.getObdDataPoints[0]
         let obdDataArray = Object.keys(obdData)
 
         obdDataArray.forEach(pidName => {
           for (var index = 0; index < this.state.pidData.length; index++) {
             // if (enrichedObdData[index].unit === '-') {
             //   enrichedObdData[index].unit = ''
             // }
             if (
               this.state.pidData[index].name.toLowerCase() ===
               pidName.toLowerCase()
             ) {
               let obdValue = obdData[pidName]
               if (
                 obdData[pidName] === 'NA' ||
                 obdData[pidName] === 'null' ||
                 obdData[pidName] === null
               ) {
                 obdValue = '-'
                 enrichedObdData[index].unit = ''
               }
               if (obdValue.includes('[')) {
                 let stringArray = obdValue.split(';')
                 console.log(obdValue, stringArray)
                 obdValue = stringArray[1]
               }
               enrichedObdData[index].data = obdValue
             }
           }
         })
       }
     )
     let absData = []
     let emsData = []
     let n
     for (n = 0; n < enrichedObdData.length; n++) {
       if (
         enrichedObdData[n].name == 'front_speed' ||
         enrichedObdData[n].name == 'ecuinputsignals1' ||
         enrichedObdData[n].name == 'abseventactive' ||
         enrichedObdData[n].name == 'abswarninglamp' ||
         enrichedObdData[n].name == 'frontwheelspeedvalidity' ||
         enrichedObdData[n].name == 'rearwheelspeedvalidity' ||
         enrichedObdData[n].name == 'rear_speed'
       ) {
         absData.push(enrichedObdData[n])
       } else {
         emsData.push(enrichedObdData[n])
       }
     }
     this.setState({ enrichedObdData: emsData })
     this.setState({ enrichedAbsData: absData })
   }
 
   getDtcCodes = async () => {
     let response = await this.props.client.query({
       query: GET_DTC_CODES,
       variables: {
         clientLoginId: parseInt(localStorage.getItem('loginId'), 10),
         uniqueId: localStorage.getItem('vehicle')
       }
     })
 
     if (response && response.data) {
       this.setState({ dtcData: response.data.getDtcCodes })
     }
   }
 
   getValueForObd = name => {
     let localName = null
 
     if (name.includes(':')) {
       let localVar
       localName = name.split(':')
       if (localName[1] === 'A') {
         localVar = localName[0] + ' (Active)'
       } else {
         localVar = localName[0] + ' (History)'
       }
       return localVar
     } else {
       return name
     }
   }
   // getSupportedPids = async () => {
   //   let response = await this.props.client.query({
   //     query: GET_SUPPORT_PIDS,
   //     variables: {
   //       uniqueId: this.props.vehicle.uniqueId
   //     },
   //     errorPolicy: 'all'
   //   })
 
   //   if (response && response.data) {
   //     this.setState({ pidData: response.data.getSupportedPids })
   //     // this.getObdDataPoints()
   //   }
   // }
 
   render() {
     const { vehicle, classes } = this.props
 
     return (
       <div>
         <Grid container justify="center" alignItems="center">
           <Grid item>
             <Dialog
               open={this.props.open}
               onClose={this.handleClose}
               aria-labelledby="alert-dialog-title"
               aria-describedby="alert-dialog-description"
               disableBackdropClick
               fullWidth
             >
               <DialogTitle id="alert-dialog-title">
                 {'Remote Diagnostics'}
               </DialogTitle>
               <DialogContent>
                 <DialogContentText>
                   <Grid container justify="space-between">
                     <Grid item>
                       <Typography variant="button" color="primary">
                         EMS OBD Parameters
                       </Typography>
                     </Grid>
                     <Grid item>
                       <Typography
                         variant="button"
                         style={{
                           color: 'white'
                         }}
                       >
                         Last Fetched Time:{' '}
                         {this.state.obdFetchedTime
                           ? getFormattedTime(this.state.obdFetchedTime, 'LLL')
                           : '-'}
                       </Typography>
                     </Grid>
                   </Grid>
                 </DialogContentText>
                 <DialogContentText id="alert-dialog-description">
                   <Grid container spacing={2}>
                     {this.state.enrichedObdData.length > 0 ? (
                       this.state.enrichedObdData.map(obdCardData => (
                         <Grid item sm={3}>
                           <ValueCard
                             alertName={obdCardData.description}
                             value={
                               obdCardData.description == 'Fuel Level'
                                 ? `${obdCardData.data}`
                                 : `${obdCardData.data} ${obdCardData.unit}`
                             }
                             alertDescription={
                               obdCardData.description == 'Fuel Level'
                                 ? 'Fuel Bar'
                                 : obdCardData.description
                             }
                             alertCount={0}
                             cardColor={COLOR_RANGE.sunshine}
                           />
                         </Grid>
                       ))
                     ) : this.state.obdLoading ? (
                       <CircularProgress />
                     ) : (
                       <Typography>No data found</Typography>
                     )}
                   </Grid>
                 </DialogContentText>
                 <br />
                 <hr />
                 <br />
                 {/* Data from Abs Points */}
                 <DialogContentText>
                   <Grid container justify="space-between">
                     <Grid item>
                       <Typography variant="button" color="primary">
                         ABS OBD Parameters
                       </Typography>
                     </Grid>
                     <Grid item>
                       <Typography
                         variant="button"
                         style={{
                           color: 'white'
                         }}
                       >
                         Last Fetched Time:{' '}
                         {this.state.obdFetchedTime
                           ? getFormattedTime(this.state.obdFetchedTime, 'LLL')
                           : '-'}
                       </Typography>
                     </Grid>
                   </Grid>
                 </DialogContentText>
                 <DialogContentText id="alert-dialog-description">
                   <Grid container spacing={2}>
                     {this.state.enrichedAbsData.length > 0 ? (
                       this.state.enrichedAbsData.map(obdCardData => (
                         <Grid item sm={3}>
                           <ValueCard
                             alertName={obdCardData.description}
                             value={
                               obdCardData.description == 'Fuel Level'
                                 ? `${obdCardData.data}`
                                 : `${obdCardData.data} ${obdCardData.unit}`
                             }
                             alertDescription={
                               obdCardData.description == 'Fuel Level'
                                 ? 'Fuel Bar'
                                 : obdCardData.description
                             }
                             alertCount={0}
                             cardColor={COLOR_RANGE.sunshine}
                           />
                         </Grid>
                       ))
                     ) : this.state.obdLoading ? (
                       <CircularProgress />
                     ) : (
                       <Typography>No data found</Typography>
                     )}
                   </Grid>
                 </DialogContentText>
                 {/* end */}
                 <br />
                 <hr />
                 <br />
 
 
                 <DialogContentText id="dtc-dialog-description">
                 <DialogContentText>
                   <Grid container spacing={1}>
                     <Grid item>
                       <Typography variant="button" color="primary">
                         EMS DTCS
                       </Typography>
                     </Grid>
                   </Grid>
                 </DialogContentText>
                   <Grid container spacing={2}>
                     {this.state.dtcData ? (
                       this.state.dtcData.length > 0 ? (
                         this.state.dtcData.map(data =>
                           data.dtcData.map(ele => {
                             if((!ele.dtcDescription.includes('ABS'))){
                               return (
                                 <Grid item sm={3}>
                                   <Grid item>
                                   </Grid>
                                   <ValueCard
                                     alertName={ele.dtcCode}
                                     value={this.getValueForObd(ele.dtcCode)}
                                     alertDescription={ele.dtcDescription}
                                     alertCount={0}
                                     cardColor={COLOR_RANGE.sunshine}
                                     time={getFormattedTime(ele.ts, 'LLL')}
                                   />
                                 </Grid>
                                 )}                           
                           })
                         )
                       ) : (
                         <Typography>No data found</Typography>
                       )
                     ) : (
                       <CircularProgress />
                     )}
                   </Grid>
                 </DialogContentText>
                 <br />
                 <hr />
                 <br />
                 <DialogContentText id="dtc-dialog-description">
                 <DialogContentText>
                   <Grid container spacing={1}>
                     <Grid item>
                       <Typography variant="button" color="primary">
                         ABS DTCS
                       </Typography>
                     </Grid>
                   </Grid>
                 </DialogContentText>
                   <Grid container spacing={2}>
                     {this.state.dtcData ? (
                       this.state.dtcData.length > 0 ? (
                         this.state.dtcData.map(data =>
                           data.dtcData.map(ele => {
                             if(ele.dtcDescription.includes('ABS')){
                               return (
                                 <Grid item sm={3}>
                                   <Grid item>
                                   </Grid>
                                   <ValueCard
                                     alertName={ele.dtcCode}
                                     value={this.getValueForObd(ele.dtcCode)}
                                     alertDescription={ele.dtcDescription}
                                     alertCount={0}
                                     cardColor={COLOR_RANGE.sunshine}
                                     time={getFormattedTime(ele.ts, 'LLL')}
                                   />
                                 </Grid>
                                 )     
                               }                       
                           })
                         )
                       ) : (
                         <Typography>No data found</Typography>
                       )
                     ) : (
                       <CircularProgress />
                     )}
                   </Grid>
                 </DialogContentText>
                 <br />
                 <hr />
                 <br />
               </DialogContent>
               <DialogActions>
                 <Button
                   onClick={this.handleClose}
                   color="primary"
                   variant="contained"
                 >
                   Close
                 </Button>
               </DialogActions>
             </Dialog>
           </Grid>
         </Grid>
       </div>
     )
   }
 }
 
 export default withStyles(style)(
   withApollo(RemoteDiagnostics)
 )
 