import { ApolloLink, split } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { ApolloClient } from "apollo-client";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { InMemoryCache } from "apollo-boost";
import { setContext } from "apollo-link-context";
import decode from "jwt-decode";

export default function getApolloClient() {
  const url = window.location.href;
  // var parameters = window.location.search.substring(1).split("&");

  // var temp = parameters[0].split("=");
  // var queryToken = unescape(temp[1]);
  // localStorage.setItem('token', queryToken.replace(/^"(.*)"$/, '$1'))

  var pathArray = url.split("/");
  //   console.log(pathArray[3]);
  if (pathArray[4]) {
    localStorage.setItem("token", pathArray[4]);
  }

  const token = localStorage.getItem("token");
  // console.log(token.length)
  if (token) {
    const { iat, uniqueId, apiUri, title, httpUri, loginId, ruleValue } = decode(token);
    localStorage.setItem("iat", iat);
    localStorage.setItem("vehicle", uniqueId);
    localStorage.setItem("apiUri", apiUri);
    localStorage.setItem("httpUri", httpUri);
    localStorage.setItem("title", title);
    localStorage.setItem("loginId",loginId);
    localStorage.setItem("ruleValue",ruleValue);
  }

  // if(localStorage.getItem('exp') > Math.round(new Date().getTime() / 1000))
  // {
  //   console.log('valid')
  // }

  // toko = eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2dpbklkIjozLCJ1c2VybmFtZSI6IndpY2tlZHJpZGUiLCJyb2xlcyI6IkNMSUVOVCIsImlhdCI6MTU5Nzk4ODAzNSwiZXhwIjoxNTk4MDA2MDM1fQ.AItfGHFwn2lmCns00UNgnMlfAfadCgKP68gFniW_GPc

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists

    // return the headers to the context so httpLink can read them

    /* eslint-disable indent */
    return token
      ? {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
          },
        }
      : {
          headers: { ...headers },
        };
    /* eslint-enable indent */
  });

  const wsLink = new WebSocketLink({
    uri: localStorage.getItem("apiUri"),

    options: {
      lazy: true,
      reconnect: true,
      connectionParams: () => ({
        Authorization: token,
      }),
    },
  });

  const httpLink = new HttpLink({
    uri: localStorage.getItem("httpUri"),
  });

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    authLink.concat(httpLink)
  );

  const client = new ApolloClient({
    link: ApolloLink.from([link]),
    cache: new InMemoryCache(),
  });

  return client;
}
