/**
 * @module ValueCard
 * @summary ValueCard module exports the ValueCard component
 */

import React from 'react'
import PropTypes from 'prop-types'
import DefaultIcon from '@material-ui/icons/Warning'
// import { THEME_MAIN_COLORS as COLOR_RANGE } from '@zeliot/common/constants/styles'
import {
  withStyles,
  Grid,
  Typography,
  Paper,
  Tooltip,
  Zoom,
  Badge
} from '@material-ui/core'

const style = theme => ({
  margin: {
    margin: theme.spacing(1)
  },
  title: {
    color: 'inherit'
  },
  textCenter: {
    textAlign: 'center'
  },
  textLeft: {
    textAlign: 'left'
  },
  textRight: {
    textAlign: 'right'
  },
  fullHeight: {
    height: '100%'
  },
  fullWidth: {
    width: '100%'
  }
})

const COLOR_RANGE = {
  sunshine: '#F7B733',
  green: '#7ebc59'
 }

/**
 * @param {object} props React component props
 * @summary ValueCard component renders a Card for rendering alert type with logo & title
 * and badge for showing count of alerts
 */
function ValueCard(props) {
  const {
    classes,
    value,
    filter,
    alertCount,
    alertDescription,
    clicked,
    cardColor,
    alertName,
    time
  } = props

  return (
    <Badge
      color="primary"
      badgeContent={alertCount}
      invisible={alertCount <= 0}
      className={classes.fullHeight + ' ' + classes.fullWidth}
    >
      <Tooltip
        TransitionComponent={Zoom}
        title={time ? time : alertDescription}
        style={{ cursor: 'pointer' }}
      >
        <Paper
          square
          elevation={clicked ? 1 : 6}
          onClick={filter}
          className={classes.fullWidth}
        >
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: cardColor,
                textAlign: 'center',
                padding: 8
              }}
            >
              <Typography
                variant="body2"
                style={{ fontSize: 14 }}
                align="center"
                color={'textPrimary'}
              >
                {value}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ padding: 4 }}>
              <Typography
                variant="body2"
                style={{ fontSize: 12 }}
                align="center"
                color={'textPrimary'}
              >
                {alertDescription}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Tooltip>
    </Badge>
  )
}

ValueCard.propTypes = {
  alertName: PropTypes.string,
  value: PropTypes.string,
  alertDescription: PropTypes.string,
  alertCount: PropTypes.number,
  cardColor: PropTypes.string,
  filter: PropTypes.func,
  alertType: PropTypes.string,
  clicked: PropTypes.bool,
  time: PropTypes.string
}

ValueCard.defaultProps = {
  alertName: 'Default',
  value: 'N/A',
  alertDescription: 'This is default alert description',
  alertCount: 0,
  cardColor: COLOR_RANGE.green
}

export default withStyles(style)(ValueCard)
