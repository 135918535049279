/**
 * Stores some constants including Icons, URLs, Arrays & objects
 * @module constants/others
 * @summary Stores some constants used throughout the app
 */
import PanicIcon from '@material-ui/icons/NotificationsActive'
import OverspeedIcon from '@material-ui/icons/NetworkCheck'
import TowIcon from '@material-ui/icons/RvHookup'
import FallIcon from '@material-ui/icons/TrendingDown'
import NoresponseIcon from '@material-ui/icons/SyncProblem'
import ExtBatLowIcon from '@material-ui/icons/BatteryAlert'
import IntBatLowIcon from '@material-ui/icons/Battery20'
import PulloutIcon from '@material-ui/icons/PowerOff'
import GeofenceIcon from '@material-ui/icons/TabUnselected'
import RoutefenceIcon from '@material-ui/icons/CallSplit'
import HaltIcon from '@material-ui/icons/Error'
import IdleIcon from '@material-ui/icons/Schedule'
import TripCompleteIcon from '@material-ui/icons/DoneAll'
import TripLiveIcon from '@material-ui/icons/NearMe'
import TripScheduledIcon from '@material-ui/icons/Alarm'
import AllTripsIcon from '@material-ui/icons/Star'
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate'
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation'
import PanToolIcon from '@material-ui/icons/PanTool'
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled'

import ConsolidatedReportIcon from '../static/svg/reports/consolidated.svg'
import AOIReportIcon from '../static/svg/reports/aoi.svg'
import SummaryReportIcon from '../static/svg/reports/summary.svg'
import DayWiseReportIcon from '../static/svg/reports/day-wise.svg'
import HaltReportIcon from '../static/svg/reports/halt.svg'
import IdlingReportIcon from '../static/svg/reports/idling.svg'
import IgnitionReportIcon from '../static/svg/reports/ignition.svg'
import J1939ReportIcon from '../static/svg/reports/J1939.svg'
import OverspeedReportIcon from '../static/svg/reports/overspeed.svg'
import OBDReportIcon from '../static/svg/reports/obd.svg'
import PanicReportIcon from '../static/svg/reports/panic.svg'
import TrackingReportIcon from '../static/svg/reports/tracking.svg'
import TripReportIcon from '../static/svg/reports/trip.svg'

import GreenCar from '../static/png/green_car.png'
import YellowCar from '../static/png/yellow_car.png'
import RedCar from '../static/png/red_car.png'
import GrayCar from '../static/png/gray_car.png'
import BlackCar from '../static/png/black_car.png'
import BlueCar from '../static/png/blue_car.png'

import GreenBike from '../static/png/green_bike.png'
import YellowBike from '../static/png/yellow_bike.png'
import RedBike from '../static/png/red_bike.png'
import GrayBike from '../static/png/gray_bike.png'
import BlackBike from '../static/png/black_bike.png'
import BlueBike from '../static/png/blue_bike.png'

import GreenTruck from '../static/png/green_truck.png'
import YellowTruck from '../static/png/yellow_truck.png'
import RedTruck from '../static/png/red_truck.png'
import GrayTruck from '../static/png/gray_truck.png'
import BlackTruck from '../static/png/black_truck.png'
import BlueTruck from '../static/png/blue_truck.png'

import GreenRoller from '../static/png/green_roller.png'
import YellowRoller from '../static/png/yellow_roller.png'
import RedRoller from '../static/png/red_roller.png'
import GrayRoller from '../static/png/gray_roller.png'
import BlackRoller from '../static/png/black_roller.png'

import GreenBus from '../static/png/green_bus.png'
import YellowBus from '../static/png/yellow_bus.png'
import RedBus from '../static/png/red_bus.png'
import GrayBus from '../static/png/gray_bus.png'
import BlackBus from '../static/png/black_bus.png'

import GreenDot from '../static/png/green_dot.png'
import YellowDot from '../static/png/yellow_dot.png'
import RedDot from '../static/png/red_dot.png'
import GrayDot from '../static/png/grey_dot.png'
import BlackDot from '../static/png/brown_dot.png'

const GreenAmb = GreenTruck
const YellowAmb = YellowTruck
const RedAmb = RedTruck
const GrayAmb = GrayTruck
const BlackAmb = BlackTruck
const BlueAmb = BlueTruck

/**
 * @summary Short form of day names stored as an array
 */
export const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

/**
 * @summary 24 hour marks stored in an array in readable 12 hour formats
 */
export const time = [
  '12 AM',
  '1 AM',
  '2 AM',
  '3 AM',
  '4 AM',
  '5 AM',
  '6 AM',
  '7 AM',
  '8 AM',
  '9 AM',
  '10 AM',
  '11 AM',
  '12 PM',
  '1 PM',
  '2 PM',
  '3 PM',
  '4 PM',
  '5 PM',
  '6 PM',
  '7 PM',
  '8 PM',
  '9PM',
  '10 PM',
  '11 PM'
]

/**
 * @summary The URL for Google Maps API library using the API_KEY from the `env`
 */
export const GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=geometry,places`

/**
 * @summary Travel replay duration between each packet
 */
export const REPLAY_DURATION = 2500

/**
 * @summary Difference between UTC & IST in minutes
 */
export const MINUTES = 330

/**
 * @summary The link to the user manual
 */
export const USER_MANUAL_DOWNLOAD_LINK =
  // 'https://storage.googleapis.com/aquilatrack-prod-static-assets/assets/user-manual/User_Manual.pdf'
  ' https://storage.googleapis.com/aquilatrack-prod-static-assets/assets/user-manual/User%20Manual_WebApp.pdf'

/**
 * @summary The link to the app in App Store
 */
export const APP_STORE_LINK =
  'https://itunes.apple.com/us/app/aquilatrack/id1454762649?ls=1&mt=8'

/**
 * @summary The link to the app in Play Store
 */
export const PLAY_STORE_LINK =
  'https://play.google.com/store/apps/details?id=com.zeliot.aquilatrack'

/**
 * @summary An array of gender objects
 */
export const GENDERS = [
  {
    value: 'F',
    label: 'Female'
  },
  {
    value: 'M',
    label: 'Male'
  },
  {
    value: 'T',
    label: 'Transgender'
  },
  {
    value: 'O',
    label: 'Other'
  }
]

/**
 * @summary Array of Alert Icon objects
 */
export const ALERT_ICONS = [
  {
    type: 'panic',
    icon: PanicIcon
  },
  {
    type: 'overspeed',
    icon: OverspeedIcon
  },
  {
    type: 'tow',
    icon: TowIcon
  },
  {
    type: 'fall',
    icon: FallIcon
  },
  {
    type: 'noResponse',
    icon: NoresponseIcon
  },
  {
    type: 'ExtBatLow',
    icon: ExtBatLowIcon
  },
  {
    type: 'IntBatLow',
    icon: IntBatLowIcon
  },
  {
    type: 'pullout',
    icon: PulloutIcon
  },
  {
    type: 'aoi',
    icon: GeofenceIcon
  },
  {
    type: 'routefence',
    icon: RoutefenceIcon
  },
  {
    type: 'halt',
    icon: HaltIcon
  },
  {
    type: 'idle',
    icon: IdleIcon
  },
  {
    type: 'dtc',
    icon: AssignmentLateIcon
  },
  {
    type: 'fuelLow',
    icon: LocalGasStationIcon
  },
  {
    type: 'ha',
    icon: PanToolIcon
  },
  {
    type: 'hb',
    icon: PauseCircleFilledIcon
  }
]

/**
 * @summary Array of vehicle icon objects
 */
export const VEHICLE_ICONS = [
  {
    vehicleType: 'Car',
    icons: {
      running: GreenCar,
      idle: YellowCar,
      halt: RedCar,
      nogps: GrayCar,
      offline: BlackCar,
      default: BlueCar
    }
  },
  {
    vehicleType: 'Bike',
    icons: {
      running: GreenBike,
      idle: YellowBike,
      halt: RedBike,
      nogps: GrayBike,
      offline: BlackBike,
      default: BlueBike
    }
  },
  {
    vehicleType: 'Ambulance',
    icons: {
      running: GreenAmb,
      idle: YellowAmb,
      halt: RedAmb,
      nogps: GrayAmb,
      offline: BlackAmb,
      default: BlueAmb
    }
  },
  {
    vehicleType: 'Truck',
    icons: {
      running: GreenTruck,
      idle: YellowTruck,
      halt: RedTruck,
      nogps: GrayTruck,
      offline: BlackTruck,
      default: BlueTruck
    }
  },
  {
    vehicleType: 'Roller',
    icons: {
      running: GreenRoller,
      idle: YellowRoller,
      halt: RedRoller,
      nogps: GrayRoller,
      offline: BlackRoller,
      default: GreenRoller
    }
  },
  {
    vehicleType: 'School Bus',
    icons: {
      running: GreenBus,
      idle: YellowBus,
      halt: RedBus,
      nogps: GrayBus,
      offline: BlackBus,
      default: GreenBus
    }
  },
  {
    vehicleType: 'Bus',
    icons: {
      running: GreenBus,
      idle: YellowBus,
      halt: RedBus,
      nogps: GrayBus,
      offline: BlackBus,
      default: GreenBus
    }
  },
  {
    vehicleType: 'Marker',
    icons: {
      running: GreenDot,
      idle: YellowDot,
      halt: RedDot,
      nogps: GrayDot,
      offline: BlackDot,
      default: GreenDot
    }
  }
]

/**
 * @summary Array of Trip status objects
 */
export const TRIP_STATUS_TYPES = [
  {
    name: 'All',
    description: 'All trips',
    count: 0,
    icon: AllTripsIcon,
    key: null
  },
  {
    name: 'Completed',
    description: 'Trips with completed schedules',
    count: 0,
    icon: TripCompleteIcon,
    key: 5
  },
  {
    name: 'In progress',
    description: 'Trip with active schedules',
    count: 0,
    icon: TripLiveIcon,
    key: 4
  },
  // {
  //   name: 'Active',
  //   description: 'All Active trips',
  //   count: 0,
  //   icon: TripScheduledIcon,
  //   key: 0
  // },
  {
    name: 'Paused',
    description: 'Trips with paused schedules',
    count: 0,
    icon: TripScheduledIcon,
    key: 2
  },
  {
    name: 'Deleted',
    description: 'Trips deleted',
    count: 0,
    icon: TripScheduledIcon,
    key: 3
  }
]

export const REPORT_ICONS = {
  'AOI IN/OUT Report': AOIReportIcon,
  'Consolidated Report': ConsolidatedReportIcon,
  'Current Summary Report': SummaryReportIcon,
  'Day Wise Report': DayWiseReportIcon,
  'Halt Report': HaltReportIcon,
  'Idling Report': IdlingReportIcon,
  'Ignition ON/OFF Report': IgnitionReportIcon,
  'J1939 Report': J1939ReportIcon,
  'OBD II Report': OBDReportIcon,
  'Overspeed Report': OverspeedReportIcon,
  'Panic Report': PanicReportIcon,
  'Tracking Report': TrackingReportIcon,
  'Trip Report': TripReportIcon
}

export const DEFAULT_USER_CONFIG = {
  config: {
    theme: {},
    configuration: {}
  },
  plan: {
    name: 'Enterprise',

    features: [
      {
        key: 'ANALYTICS_DASHBOARD'
      },
      {
        key: 'ALERTS_CONFIG'
      },
      {
        key: 'REPORT'
      },
      {
        key: 'MANAGE_USERS'
      },
      {
        key: 'MANAGE_DRIVERS'
      },
      {
        key: 'MANAGE_VEHICLES'
      },
      {
        key: 'MANAGE_ACCOUNT'
      },
      {
        key: 'GOOGLE_MAPS'
      },
      {
        key: 'AOI'
      },
      {
        key: 'ROUTES'
      },
      {
        key: 'TRIPS'
      },
      {
        key: 'IMMOBILIZE'
      },
      // {
      //   key: 'DEVICE_MANAGEMENT'
      // },
      {
        key: 'SIM_MANAGEMENT'
      }
      // {
      //   key: 'FOTA_MANAGEMENT'
      // },
      // {
      //   key: 'PROVISIONING'
      // }
    ]
  }
}
