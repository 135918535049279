import React, { Component } from "react";
import { Grid, Typography, withStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import gql from "graphql-tag";
import { Helmet } from "react-helmet";
import { withApollo } from "react-apollo";

const GET_DOMAIN_CONFIG = gql`
  query($domain: String!) {
    domainConfiguration(domain: $domain) {
      header {
        title
      }
      page {
        logo
        navbarConfig {
          logo
          title
        }
      }
    }
  }
`;

const styles = (theme) => ({
  root: {
    height: "100%",
    backgroundColor:"#0d0d0d"
  },
  appBar: {
    zIndex: theme.zIndex.drawer,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    backgroundColor:"#616161"
  },
  flex: {
    flex: 1,
  },
});

class NavBar extends Component {
  state = {
    config: "",
    // logo: ''
  };

  componentDidMount() {
    // this.getConfig();
  }

  getConfig = async () => {
    const response = await this.props.client.query({
      query: GET_DOMAIN_CONFIG,
      variables: { domain: window.location.host },
    });

    if (response) {
      console.log("response", response);
      // Title = response

      // console.log('Title',Title)
      this.setState(
        { config: response.data.domainConfiguration }
        //   , () => {
        //   document.title = this.state.config.header.title
        // }
      );
      // console.log('Title.data.domainConfiguration.header.title',Title.data.domainConfiguration.header.title)
    }
  };

  render() {
    const { classes } = this.props;
    console.log(this.props);
    return (
      <Grid container spacin={2}>
        <Grid item xs={12}>
            <>
              <Helmet>
                <title>{localStorage.getItem("title")}</title>
                <link rel="shortcut icon" />
              </Helmet>
              <AppBar
                position="fixed"
                color="secondary"
                className={classes.appBar}
              >
                <Typography
                  variant="h6"
                  style={{
                    marginLeft: "30px",
                  }}
                >
                  {localStorage.getItem("title")
                    ? localStorage.getItem("title")
                    : ""}
                </Typography>
              </AppBar>
            </>
        </Grid>
      </Grid>
    );
  }
}

export default withApollo(withStyles(styles)(NavBar));
